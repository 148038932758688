<script>
  import { onMount } from "svelte";

  import logoBoxesStill from "$lib/assets/3d/logo-boxes-still.png";

  let LogoBoxes3D = null;

  onMount(() => {
    import("$lib/ui/marketing/LogoBoxes3D.svelte").then((module) => {
      LogoBoxes3D = module.default;
    });
  });
</script>

{#if LogoBoxes3D}
  <svelte:component this={LogoBoxes3D} />
{:else}
  <div
    class="h-full w-full"
    style:background={`url(${logoBoxesStill}) no-repeat center center / auto 100%`}
  />
{/if}
